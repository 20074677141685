<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-overlay :show="loadingState">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationProvider name="Signature" vid="signature" ref="signature" rules="">
                                    <b-form-group class="row" label-cols-sm="4" label-for="signature" slot-scope="{ valid, errors }">
                                        <template v-slot:label>
                                        {{ $t('result_submission.attachment') }}
                                        <!-- <v-slot v-if="main_form.signature !== null">
                                            <a :href="baseUrl + 'download-attachment?file=uploads/general-information/attachment/original/' + main_form.signature" title="Evaluation Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                        </v-slot> -->
                                        </template>
                                        <b-form-file
                                            id="signature"
                                            v-model="doc"
                                            accept=".png, .jpg, .jpeg, .svg"
                                            @change="onSignatureChange"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-file>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                  <ValidationProvider name="Lab Office Type" vid="lab_office_type" rules="required|min_value:1" >
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="lab_office_type"
                                          slot-scope="{ valid, errors }"
                                      >
                                          <template v-slot:label>
                                              {{ $t('result_submission.lab_office_type') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-select
                                              plain
                                              id="lab_office_type"
                                              v-model="formData.lab_office_type"
                                              :options="officeTypeList"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="''" >{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                                <b-col md="12">
                                  <ValidationProvider name="Unit of Measurement" vid="unit_of_mesurement" rules="required|min_value:1" >
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="4"
                                          label-for="user_id"
                                          slot-scope="{ valid, errors }"
                                      >
                                          <template v-slot:label>
                                              {{ $t('result_submission.lab_office') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-select
                                              plain
                                              id="user_id"
                                              v-model="formData.lab_id"
                                              :options="officeList"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="''" >{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                                <b-col lg="12" sm="12">
                                    <b-form-group class="row" label-cols-sm="4" label-for="remarks" >
                                        <template v-slot:label>
                                            {{$t('research_test.remarks')}}
                                        </template>
                                        <b-form-textarea
                                            plain
                                            id="remarks"
                                            v-model="formData.remarks"
                                        >
                                        </b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../config/api_config'
import { sampleRegistrationAndTestingStoreApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
    name: 'FormLayout',
    props: ['items'],
    mixins: [ModalBaseMasterList],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            saveBtnName: this.$t('globalTrans.save'),
            tmpSignature: [],
            formData: {
                general_info_id: '',
                attachment: '',
                lab_id: '',
                lab_office_type: '',
                remarks: ''
            },
            officeList: [],
            doc: ''
        }
    },
    watch: {
        'formData.lab_office_type': function (newVal, oldVal) {
            this.getOfficeList(newVal)
        }
    },
    created () {
        // this.schedule.id = this.items.id
        // this.schedule.date = this.items.date_of_collection
        // this.schedule.remarks = this.items.remarks
    },
    computed: {
        labInfoList: function () {
            const labInfoList = this.$store.state.AgriResearch.commonObj.labInfoList.filter(item => item.status === 0)
            return labInfoList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        officeTypeList: function () {
            const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === 14)
            return officeTypeList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        }
        // officeList: function () {
        //     const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.org_id === 14)
        //     return officeList.map(item => {
        //         return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        //     })
        // }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
    },

    methods: {
        onSignatureChange (event) {
            const input = event.target
            if (input.files && input.files[0]) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    this.formData.attachment = e.target.result
                }
                reader.readAsDataURL(input.files[0])
            } else {
                this.formData.attachment = ''
            }
        },
        getOfficeList (id) {
            this.officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.org_id === 14 && item.office_type_id === id).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            this.formData.general_info_id = this.items.id
            this.loading = true
            const result = await RestApi.postData(agriResearchServiceBaseUrl, sampleRegistrationAndTestingStoreApi, this.formData)
            if (result.success) {
                // const notification = result.notification
                // this.$socket.emit('send-notification', notification)
                this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: 'Success',
                    message: this.id ? 'Data updated successfully' : 'Data save successfully',
                    color: '#D6E09B'
                })
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                this.$bvModal.hide('modal-4')
                this.$emit('listenerChild')
            } else {
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
            }
        }
   }
}
</script>
